.ant-btn-dangerous.ant-btn-primary {
  background: var(--button-danger-bg);
  border-color: var(--button-danger-bg);
}
.ant-btn-dangerous.ant-btn-primary:not(:disabled):hover {
  background-color: var(--button-danger-bg-light) !important;
  border-color: var(--button-danger-bg-light);
}

.ant-btn-circle.ant-btn-sm {
  border-radius: 50%;
  min-width: 24px;
  font-size: 14px;
  height: 24px;
  padding: 0;
}

.ant-btn > .icon-custom + span,
.ant-btn > span + .icon-custom {
  margin-left: 6px;
}

.ant-btn:not(.ant-btn-icon-only) > .ant-btn-icon:not(:last-child) {
  margin-inline-end: 6px;
}

.ant-btn > .icon-custom {
  line-height: 1;
}
