@import '../mapEntities';

#windy
  .leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-bar.leaflet-control,
.leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-bar.leaflet-control {
  position: absolute;
  right: 17px;
  top: 122px;
  margin: 0 !important;
}

.leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-bar.leaflet-control
  #polyline-measure-control {
  @include icomoon('\e90f');
  @extend .MapControlButtonOfGroup;

  width: 40px !important;
  height: 40px !important;
  box-shadow: none;
  border-radius: 0;
  border: none !important;
  background-color: transparent !important;
  text-indent: -9999px;
  overflow: hidden;

  &:before {
    font-size: 22px;
    line-height: 38px;
    text-indent: 0;
    display: block;
    width: 100%;
  }

  &.polyline-measure-controlOnBgColor {
    color: var(--primary-color) !important;

    ~ .polyline-measure-clearControl {
      display: block !important;
    }
  }
}

.leaflet-control-container
  .leaflet-top.leaflet-right
  .leaflet-bar.leaflet-control
  .polyline-measure-clearControl {
  @include icomoon('\e901');
  @extend .MapControlShadow;

  text-indent: -9999px;
  overflow: hidden;

  position: absolute;
  left: -41px;
  top: 0;
  width: 38px !important;
  height: 38px !important;
  color: $white !important;
  background: var(--primary-color) !important;
  border-radius: var(--border-radius-base);
  display: none !important;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--primary-color-hover) !important;
    color: $white !important;
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    background-color: var(--primary-color-dark) !important;
  }

  &:before {
    font-size: 12px;
    line-height: 38px;
    text-indent: 0;
    display: block;
  }
}
