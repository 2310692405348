/* Fullscreen control */
.leaflet-control-container
  .leaflet-top.leaflet-left
  .leaflet-bar.leaflet-control:not(.leaflet-control-zoom) {
  margin: 8px 0 0 24px;

  .Body--mobile & {
    margin: 8px 0 0 8px;
  }
  .Body--fullscreen & {
    margin: 8px 0 0 24px;
  }
  .Body--ipad.Body--fullscreen & {
    margin: 8px 0 0 24px !important;
  }

  @media (orientation: landscape) {
    .Body--mobile & {
      margin: 8px 0 0 8px;
    }
    .Body--mobile.Body--ios.Body--fullscreen & {
      margin: 8px 0 0 calc(var(--sal) - 5px) !important;
    }
    .Body--ipad.Body--fullscreen & {
      margin: 8px 0 0 8px !important;
    }
  }

  @media (orientation: portrait) {
    .Body--mobile.Body--fullscreen & {
      margin: 8px 0 0 8px !important;
    }
  }

  .leaflet-control-zoom-fullscreen {
    @extend .MapControlButton;
    @include icomoon('\e907');
    font-size: 22px;
    border-radius: 40px !important;
    height: 40px !important;
    width: 40px !important;
    line-height: 40px !important;
    user-select: none;

    &[title~='Exit'] {
      @include icomoon('\e908');
    }
  }

  .fullscreen-icon {
    background-image: none;
  }
}

#windy
  .leaflet-control-container
  .leaflet-top.leaflet-left
  .leaflet-bar.leaflet-control:not(.leaflet-control-zoom) {
  margin: 112px 0 0 24px;

  .Body--mobile & {
    margin: 104px 0 0 8px;
  }
  .Body--fullscreen & {
    margin: 184px 0 0 24px;
  }
  .Body--ipad.Body--fullscreen & {
    margin: 144px 0 0 24px !important;
  }

  @media (orientation: landscape) {
    .Body--mobile & {
      margin: 104px 0 0 24px;
    }
    .Body--mobile.Body--ios.Body--fullscreen & {
      margin: 104px 0 0 calc(var(--sal) - 5px) !important;
    }
  }

  @media (orientation: portrait) {
    .Body--mobile.Body--fullscreen & {
      margin: 198px 0 0 8px !important;
    }
    .Body--ipad.Body--fullscreen & {
      margin: 219px 0 0 8px !important;
    }
  }
}
