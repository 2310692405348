/**
 */
.MapEntityCard2 {
  position: relative;
  width: 220px;
  padding: 0;
  background-color: var(--body-background);
  border-radius: var(--border-radius-base);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  &__container {
    position: relative;
  }

  &__extra {
  }
}

/**
 */
.MapEntityCardFlexible {
  @extend .MapEntityCard2;
  width: auto !important;
}

/**
 */
.MapEntityPhoto {
  display: block;
  margin: 4px 0;
  min-height: 50px;
  padding: 0px 1px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 71.35%,
      rgba(0, 0, 0, 0.3) 94.27%
    );
    background-blend-mode: multiply;
  }

  &__photo {
    cursor: pointer;
    width: 100%;
  }

  &__fav {
    @extend .MapControlButtonOfGroup;
    position: absolute;
    top: 42px;
    right: 10px;
    z-index: 2;
    cursor: pointer;
    font-size: 22px;
    transition: all 0.2s;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);

    &:not(:disabled):not(:hover):not(:active) {
      color: white;
    }

    &.icon-star-filled {
      color: $filled-star-color;
    }
  }
}

/**
 */
.MapEntityText {
  font-family: $font-inter;
  padding: 4px 8px 8px;
  color: var(--title);

  &__label {
    display: inline-block;
    color: var(--text);
    min-width: 60px;
    padding-right: 5px;
  }

  &__spacer {
    min-height: 4px;
    max-height: 4px;
  }
}

/**
 */
.MapEntityPlayButton {
  position: absolute;
  right: 3px;
  bottom: 55px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }

  span {
    @include icomoon('\e931');

    &:before {
      margin-left: 3px;
      line-height: 1.5;
    }
  }
}

/**
 */
.MapEntityHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 100%;
  overflow: hidden;
  background-color: var(--body-background); // can be removed
  border-top-left-radius: var(--border-radius-base);
  border-top-right-radius: var(--border-radius-base);

  &--shorter {
    .MapEntityHeader__info {
      width: calc(100% - 30px);
      max-width: calc(100% - 30px);
    }
  }
  &--shortest {
    .MapEntityHeader__info {
      width: calc(100% - 60px);
      max-width: calc(100% - 60px);
    }
  }

  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 4px 4px 8px;
    max-width: 100%;
  }

  &__flag {
    max-height: 16px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
  }

  &__name {
    margin: 0 0 0 4px;
  }

  &__close {
    @include icomoon('\e901');
    @extend .MapControlButtonOfGroup;
    padding: 0 8px;
    line-height: 2.5;
    height: 32px;
    font-size: 14px;
  }

  &__minimize {
    @include icomoon('\e909');
    @extend .MapControlButtonOfGroup;
    font-weight: 700;
    font-size: 20px;
    padding: 0 5px;
    line-height: 2.25;
    height: 32px;
  }
}

/**
 */
.MapControlShadow {
  &:not(:disabled):not(.ant-btn-disabled) {
    box-shadow: 0px 8px 24px 0px var(--button-default-shadow);
  }
  .Body--dark &:not(:disabled):not(.ant-btn-disabled) {
    box-shadow: 0px 8px 24px 0px var(--button-light-shadow);
  }

  &:not(:disabled):not(.ant-btn-disabled):hover {
    box-shadow: 0px 8px 24px 0px var(--button-default-shadow),
      0px 8px 24px 0px var(--button-default-shadow);
  }
  .Body--dark &:not(:disabled):not(.ant-btn-disabled):hover {
    box-shadow: 0px 8px 24px 0px var(--button-light-shadow),
      0px 8px 24px 0px var(--button-light-shadow);
  }
}

/**/
#windy .leaflet-container a,
.MapControlButtonOfGroup {
  &:not(:disabled):not(.ant-btn-disabled) {
    color: var(--text);
  }

  &:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--primary-color);
  }

  &:not(:disabled):not(.ant-btn-disabled):active {
    color: var(--primary-color-hover);
  }

  .icon-custom {
    font-size: 22px;
  }
}

/**/
.MapControlButton {
  @extend .MapControlShadow;
  @extend .MapControlButtonOfGroup;

  height: 40px !important;
  text-align: center;
  border: none !important;
  border-radius: 40px;
  background-color: var(--body-background-alpha) !important;
  transition: all 0.2s;
}

/**/
.TrackTooltip,
.VesselTooltip {
  background-color: var(--body-background) !important;
  border: none;
}

/**/
.VesselTooltip {
  .VesselNotification {
    padding: 8px 8px 0;
  }
}

/**/
.LayerValuePopup {
  width: auto !important;
  min-width: max-content;

  &__text {
    padding-top: 8px;
  }
}

/*
  antd.message "Vessel locations updated."
*/
.LocationsUpdate__message {
  text-align: left;
  padding-left: calc(var(--sider-closed-width) + 80px);

  @include breakpoint(md) {
    padding-left: 80px;
  }
}
