.leaflet-container .leaflet-top.leaflet-right {
  height: 0;

  .Body--touch & {
    float: none;
    left: 0;
  }
}

.leaflet-container .leaflet-top.leaflet-right .leaflet-control-coordinates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.7);
  padding: 0 12px;
  margin: 0 69px 0 0 !important;
  color: $black;
  font-family: $font-inter;
  font-weight: 600;
  border-radius: var(--border-radius-base);
  min-width: 270px;

  .Body--touch & {
    max-width: 230px;
    min-width: unset;
    float: none !important;
    margin: 0 auto !important;
    border-radius: 0;
  }

  .Body--ios & {
    display: none;
  }

  &-message {
    font-size: 8px;
    color: grey;
  }

  &-block {
    font-size: 14px;
    min-width: 120px;
    user-select: none;

    .Body--touch & {
      min-width: auto;
      font-size: 11px;
      line-height: 12px;
    }

    b {
      font-weight: 600;
    }

    &:first-child {
      margin-right: 1rem;
    }
  }
}
