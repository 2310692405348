@mixin placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin flex-wrap-fix($flex-basis, $max-viewport-width: 2000px) {
  flex-grow: 1;
  flex-basis: $flex-basis;
  min-width: $flex-basis;
  max-width: 100%;

  $multiplier: 1;
  $current-width: 0px;

  @while $current-width < $max-viewport-width {
    $current-width: $current-width + $flex-basis;
    $multiplier: $multiplier + 1;

    @media (min-width: $flex-basis * $multiplier) {
      max-width: percentage(1 / $multiplier);
    }
  }
}
/*
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  min-height: $lineHeight * $lineCount;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 50px;
    background: linear-gradient(
      to bottom,
      var(--body-background-alpha),
      var(--body-background)
    );
  }
}
*/

/* mixin for multiline */
/**
.block-with-text {
  @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: white);
}
 */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical;
  }
  @media not all and (-webkit-min-device-pixel-ratio: 0) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;

    &:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background: $bgColor;
    }
  }
}

/*
Breakpoints mixin
@param {String} $point - name of breakpoint
add '-up' if you want (min-width: $point) and leave with no suffix for (max-width: $point - 1px)
*/
@mixin breakpoint($point) {
  $query-direction: '';
  $query-point: '';
  $query-size: '';

  @if str-index($point, '-up') {
    $query-direction: 'min-width';
    $query-point: str-slice($point, 0, str-length($point) - str-length('-up'));
    $query-size: map-get($breakpoints, $query-point);
  } @else {
    @if str-index($point, '-down') {
      $query-point: str-slice(
        $point,
        0,
        str-length($point) - str-length('-down')
      );
    } @else {
      $query-point: $point;
    }

    $query-direction: 'max-width';
    $query-size: map-get($breakpoints, $query-point) - 1px;
  }

  @media screen and (#{$query-direction}: #{$query-size}) {
    @content;
  }
}

@mixin list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin draftState {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: #efefef;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-weight: bold;
  font-size: 30px;
  text-transform: uppercase;
}

@mixin transition {
  transition: 0.2s;
}

@mixin icomoon($iconCode) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    content: $iconCode;
  }
}

@mixin isNotTouchDevice {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin title($fontSize, $lineHeight: 1.1) {
  font-family: $font-ttl;
  font-size: $fontSize + px;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  line-height: $lineHeight;
}

/* Colors */
$text-color: #4e5b6a;

$black-dark: #000;
$black: #020814;

$grey-dark: rgba(0, 0, 0, 0.6);
$grey-medium: #979797;
$silver: #c4c4c4;
$grey: #d3d3d3;
$grey-light: #e7e7e6;
$grey-x-light: #f2f2f2;

$dark-blue: #072f61;
$primary: rgba(2, 8, 20, 0.9);

$primary-color-af: #ff6542;
$primary-color-ff: #4e74fc;

$red: #ee2b00;
$error: #f5222d;
$magenta: #f315f8;
$white: #fff;

$color-green: yellowgreen;
$color-green-light: #00e06a;
$color-probability-medium: #fec601;
$color-status-lead: #009fe3;
$color-status-meetingHeld: #bb6bd9;
$color-status-proposal: #828282;
$color-status-invoiced: #27ae60;
$color-status-history: #50d8d7;

$filled-star-color: #ffdc24;
$scroll-color: #888;
$scroll-active-color: #555;

$font-inter: 'Inter', /* default font */ Arial, sans-serif !important;
$font-ttl: 'TitilliumWeb', /* default font */ Arial, sans-serif !important;

$breakpoints: (
  /* Extra small screen / phone */ 'xs': 0,
  /* Extra small screen middle / phone */ 'xxs': 480px,
  /* Small screen / phone */ 'sm': 576px,
  /* Medium screen / tablet */ 'md': 768px,
  /* Large screen / desktop */ 'lg': 992px,
  /* Large / Extra large screen */ 'xlg': 1024px,
  /* Extra large screen / wide desktop */ 'xl': 1200px,
  'xxl': 1540px
) !default;

.HCSwipeMenu {
  $hc-button-size: 40px;
  --menu-width: 600px;

  left: calc(50% - (var(--menu-width) / 2) + (var(--sider-closed-width)) / 2);
  width: var(--menu-width);

  //position: relative;
  //width: 660px;
  //padding: 16px 8px;
  //background: var(--body-background-alpha);
  //margin-bottom: 16px;
  //margin-left: 16px;
  //border-radius: 0 var(--border-radius-base) var(--border-radius-base)
  //  var(--border-radius-base);
  //
  //.Body--mobile & {
  //  width: 100%;
  //  margin-bottom: 0px;
  //  margin-left: 0px;
  //  border-radius: 0;
  //
  //  @media (orientation: landscape) {
  //    width: calc(100vw - var(--sar) - var(--sider-closed-width));
  //  }
  //}
  //
  //.Body--mobile.Body--ios & {
  //  padding-bottom: calc(var(--sab) - 15px);
  //
  //  @media (orientation: landscape) {
  //    padding-bottom: 20px;
  //  }
  //}
  //
  //.Body--mobile.Body--fullscreen &,
  //.Body--ipad.Body--fullscreen &,
  //.Body--app.Body--fullscreen & {
  //  display: none;
  //}
  //
  //@include breakpoint(xlg-up) {
  //  max-width: 650px;
  //}

  //&__head {
  //  position: absolute;
  //  left: 0;
  //  top: -28px;
  //  display: flex;
  //  justify-content: space-between;
  //  width: 100%;
  //}

  &__content {
    height: 144px;
    padding: 8px;
    margin-bottom: 16px;
    background: var(--body-background-alpha);
    border-radius: var(--border-radius-base);
  }

  &__trigger {
    position: absolute;
    left: calc(
      50% - calc($hc-button-size / 2) + (var(--sider-closed-width)) / 2
    );
    bottom: 16px;
    //z-index: 500;
    //margin: 0 0 100px 100px;

    //@include breakpoint(md) {
    //  position: absolute;
    //  left: 8px;
    //  margin-left: 0;
    //  bottom: -27px;
    //}

    //.Body--mobile & {
    //  margin-left: 8px;
    //  //margin-bottom: 4px;
    //}
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $hc-button-size;
    width: $hc-button-size;
    min-width: $hc-button-size;
    max-width: $hc-button-size;
    animation: fadeInDelayed 0.45s;
  }
}
