@font-face {
  font-family: 'icomoon';
  src: url('icomoon.woff?s70ih1') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-opacity:before {
  content: '\e968';
}
.icon-at-sign:before {
  content: '\e967';
}
.icon-basket:before {
  content: '\e95e';
}
.icon-calendar-plus:before {
  content: '\e95f';
}
.icon-depth:before {
  content: '\e960';
}
.icon-equipment:before {
  content: '\e961';
}
.icon-historical-catch:before {
  content: '\e962';
}
.icon-fishing-gear:before {
  content: '\e963';
}
.icon-location-pin:before {
  content: '\e964';
}
.icon-time:before {
  content: '\e965';
}
.icon-timer:before {
  content: '\e966';
}
.icon-caret-down:before {
  content: '\e95d';
}
.icon-sea-subsurface-temp:before {
  content: '\e958';
}
.icon-zooplankton:before {
  content: '\e95b';
}
.icon-phytoplankton:before {
  content: '\e95c';
}
.icon-salinity:before {
  content: '\e959';
}
.icon-sea-surface-temp:before {
  content: '\e95a';
}
.icon-stars-awesome:before {
  content: '\e957';
}
.icon-folder-minus:before {
  content: '\e955';
}
.icon-folder-delete:before {
  content: '\e956';
}
.icon-biolytics:before {
  content: '\e923';
}
.icon-companies:before {
  content: '\e94a';
}
.icon-items:before {
  content: '\e952';
}
.icon-newspaper:before {
  content: '\e953';
}
.icon-activity-profile:before {
  content: '\e945';
}
.icon-bar-chart:before {
  content: '\e946';
}
.icon-calendar-dates:before {
  content: '\e947';
}
.icon-case:before {
  content: '\e948';
}
.icon-clipboard-text:before {
  content: '\e949';
}
.icon-external-link:before {
  content: '\e94b';
}
.icon-home:before {
  content: '\e94c';
}
.icon-map:before {
  content: '\e94d';
}
.icon-menu:before {
  content: '\e94e';
}
.icon-table:before {
  content: '\e94f';
}
.icon-tote:before {
  content: '\e950';
}
.icon-vessel:before {
  content: '\e951';
}
.icon-rotate-ccw:before {
  content: '\e944';
}
.icon-eez:before {
  content: '\e943';
}
.icon-ices:before {
  content: '\e941';
}
.icon-sea-temp:before {
  content: '\e942';
}
.icon-play-circle:before {
  content: '\e940';
}
.icon-time-history:before {
  content: '\e93f';
}
.icon-ice:before {
  content: '\e93e';
}
.icon-gusts:before {
  content: '\e93d';
}
.icon-maximize-card:before {
  content: '\e93c';
}
.icon-anchor:before {
  content: '\e935';
}
.icon-book:before {
  content: '\e936';
}
.icon-flag:before {
  content: '\e937';
}
.icon-lightbulb:before {
  content: '\e938';
}
.icon-person:before {
  content: '\e939';
}
.icon-service-provider:before {
  content: '\e93a';
}
.icon-trending-up:before {
  content: '\e93b';
}
.icon-alert-triangle:before {
  content: '\e934';
}
.icon-download:before {
  content: '\e933';
}
.icon-pause:before {
  content: '\e930';
}
.icon-play:before {
  content: '\e931';
}
.icon-speed:before {
  content: '\e932';
}
.icon-loading:before {
  content: '\e92a';
}
.icon-arrow-left:before {
  content: '\e92b';
}
.icon-check:before {
  content: '\e92c';
}
.icon-drag:before {
  content: '\e92d';
}
.icon-paperclip:before {
  content: '\e92e';
}
.icon-upload:before {
  content: '\e92f';
}
.icon-layers:before {
  content: '\e929';
}
.icon-contour:before {
  content: '\e928';
}
.icon-direction:before {
  content: '\e926';
}
.icon-location:before {
  content: '\e927';
}
.icon-facebook-f:before {
  content: '\e924';
}
.icon-linkedin-in:before {
  content: '\e925';
}
.icon-chevron-right:before {
  content: '\e91e';
}
.icon-chevron-up:before {
  content: '\e922';
}
.icon-facebook:before {
  content: '\e91d';
}
.icon-info:before {
  content: '\e91f';
}
.icon-linkedin-blue:before {
  content: '\e920';
}
.icon-twitter-blue:before {
  content: '\e921';
}
.icon-seabed-2:before {
  content: '\e91c';
}
.icon-weather:before {
  content: '\e91b';
}
.icon-delete:before {
  content: '\e919';
}
.icon-pencil-create:before {
  content: '\e91a';
}
.icon-map-point:before {
  content: '\e916';
}
.icon-star:before {
  content: '\e917';
}
.icon-star-filled:before {
  content: '\e918';
}
.icon-bell:before {
  content: '\e900';
}
.icon-close:before {
  content: '\e901';
}
.icon-currents:before {
  content: '\e902';
}
.icon-down:before {
  content: '\e903';
}
.icon-ellipsis:before {
  content: '\e904';
}
.icon-filters:before {
  content: '\e905';
}
.icon-map-info:before {
  content: '\e906';
}
.icon-maximize:before {
  content: '\e907';
}
.icon-minimize:before {
  content: '\e908';
}
.icon-minus:before {
  content: '\e909';
}
.icon-moon:before {
  content: '\e90a';
}
.icon-photo:before {
  content: '\e90b';
}
.icon-plus:before {
  content: '\e90c';
}
.icon-radar:before {
  content: '\e90d';
}
.icon-route:before {
  content: '\e90e';
}
.icon-ruller:before {
  content: '\e90f';
}
.icon-seabed:before {
  content: '\e910';
}
.icon-search:before {
  content: '\e911';
}
.icon-sun:before {
  content: '\e912';
}
.icon-thermometer:before {
  content: '\e913';
}
.icon-water-waves:before {
  content: '\e914';
}
.icon-wind-sign:before {
  content: '\e915';
}
.icon-link:before {
  content: '\e954';
}
